import React from 'react';
import Helmet from 'react-helmet';
import useTimeout from 'use-timeout';
import { LiveMessage, LiveAnnouncer } from 'react-aria-live';
import queryString from 'query-string';

export default function RedirectPage(props) {
	useTimeout(() => {
		let { search } = props.location;
		let { href } = queryString.parse(search);

		window.location.href = href;
	}, 3000);

	const message =
		"You're leaving whenpigsflybbq.com to go to another site. We hope to see you soon!";

	return (
		<LiveAnnouncer>
			<Helmet title="Leaving When Pigs Fly BBQ">
				<html lang="en" />

				{process.browser && (
					<script
						async
						defer
						data-domain="whenpigsflybbq.com"
						src="https://stats.whenpigsflybbq.com/js/index.outbound-links.js"
					></script>
				)}
			</Helmet>
			<div
				style={{
					width: 500,
					maxWidth: '95%',
					margin: '20px auto',
					textAlign: 'center'
				}}
			>
				<h1>Leaving When Pigs Fly BBQ</h1>

				<div>
					<LiveMessage message={message} aria-live="assertive" />
					<p>{message}</p>
				</div>
			</div>
		</LiveAnnouncer>
	);
}
